import React from 'react'
import SectionHeader from '../Shared/SectionHeader'
import { Container, Row, Col } from '../UI/Grid'
import { Section } from '../UI/Section'
import ShadowedImage from '../Shared/ShadowedImage'
import { StaticImage } from 'gatsby-plugin-image'
import Element from '../UI/Element'
import DefaultVector from '../Shared/DefaultVector'
import { EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

const Education = () => {
  return (
    <Section defaultVector py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container>
          <Row mb={15}>
            <Col col={{ lg: 6 }}>
              <SectionHeader
                title="Education"
                description="As part of Fastech's vision and mission as a technology company, it believes in the development of highly proficient labor. Throughout history, the company supported various education initiatives in this regard."
              ></SectionHeader>
              <br />
              <SectionHeader
                smaller_title="1995"
                description="Fastech started a scholarship program in Polytechnic University of the Philippines and had a total of 80 scholars in the university."
              ></SectionHeader>
            </Col>
            <Col col={{ lg: 6 }}>
              <Swiper
                modules={[Pagination, EffectFade]}
                effect="fade"
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ el: '.pagination-1995', clickable: true }}
                speed={1000}
              >
                <SwiperSlide>
                  <Element pr={4} pb={6}>
                    <ShadowedImage>
                      <StaticImage src="../../images/1995-1.jpg" alt="csr" />
                    </ShadowedImage>
                  </Element>
                </SwiperSlide>
                <SwiperSlide>
                  <Element pr={4} pb={6}>
                    <ShadowedImage>
                      <StaticImage src="../../images/1995-2.jpg" alt="csr" />
                    </ShadowedImage>
                  </Element>
                </SwiperSlide>
                <SwiperSlide>
                  <Element pr={4} pb={6}>
                    <ShadowedImage>
                      <StaticImage src="../../images/1995-3.jpg" alt="csr" />
                    </ShadowedImage>
                  </Element>
                </SwiperSlide>
              </Swiper>
              <div className="swiper-dots dots-csr pagination-1995"></div>
            </Col>
          </Row>

          <Row alignItems="center" mb={{ _: 0, lg: 16 }}>
            <Col col={{ lg: 6 }}>
              <Swiper
                modules={[Pagination, EffectFade]}
                effect="fade"
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ el: '.pagination-2018', clickable: true }}
                speed={1000}
              >
                <SwiperSlide>
                  <Element pr={4} pb={6}>
                    <ShadowedImage>
                      <StaticImage src="../../images/2018-1.jpg" alt="csr" />
                    </ShadowedImage>
                  </Element>
                </SwiperSlide>
                <SwiperSlide>
                  <Element pr={4} pb={6}>
                    <ShadowedImage>
                      <StaticImage src="../../images/2018-2.jpg" alt="csr" />
                    </ShadowedImage>
                  </Element>
                </SwiperSlide>
                <SwiperSlide>
                  <Element pr={4} pb={6}>
                    <ShadowedImage>
                      <StaticImage src="../../images/2018-3.jpg" alt="csr" />
                    </ShadowedImage>
                  </Element>
                </SwiperSlide>
              </Swiper>
              <div className="swiper-dots dots-csr pagination-2018"></div>
            </Col>
            <Col col={{ lg: 6 }}>
              <SectionHeader
                smaller_title="2018"
                description="It committed another 40 scholarships over the next four years with FAITH Colleges for Electronics Engineering (ECE). It also launched a scholarship for a Master's Degree in ECE, Major in Very Large Scale Integrated Design Track."
              ></SectionHeader>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

export default Education
