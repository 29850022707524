import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Banner from '../Shared/Banner'
import ScaledImage from '../UI/ScaledImage'

const CsrBanner = () => {
  return (
    <Banner
      name="Corporate Social Responsibility"
      description="Community Development<br/>Through Various Initiatives"
    >
      <ScaledImage bg={true}>
        <StaticImage src="../../images/banner-csr.jpg" alt="banner" layout="fullWidth" />
      </ScaledImage>
    </Banner>
  )
}

export default CsrBanner
