import React from 'react'
import CsrBanner from '../components/Csr/CsrBanner'
import Site from '../components/Layout/Site'
import Education from '../components/Csr/Education'
import Seo from '../components/Layout/Seo'
import Cmd from '../components/Csr/Cmd'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import '../stylesheets/swiper-override.css'

const Csr = () => {
  return (
    <Site>
      <Seo title="Corporate Social Responsibility" />
      <CsrBanner />
      <Education />
      <Cmd />
    </Site>
  )
}

export default Csr
