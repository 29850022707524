import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Container, Row } from '../UI/Grid'
import ScaledImage from '../UI/ScaledImage'
import { Section } from '../UI/Section'
import { Heading, Paragraph } from '../UI/Typography'
import Button from '../UI/Button'
import ScaledElement from '../UI/ScaledElement'

const Cmd = () => {
  return (
    <Section color="white" scaled>
      <ScaledElement scaleHeight={400} scaleWidth={1440}>
        <ScaledImage bg={true}>
          <StaticImage src="../../images/bg-cmd.jpg" alt="bg" layout="fullWidth" />
        </ScaledImage>
        <Container>
          <Row rowGap={4}>
            <Col col={{ lg: 6 }}>
              <Heading size="h2" color="white">
                Center for Microelectronics Development (CMD)
              </Heading>
            </Col>
            <Col col={{ lg: 6 }}>
              <Paragraph>
                As part of Fastech’s vision and mission as a technology company, it believes in the
                development of highly proficient labor. Throughout history, the company supported
                various education initiatives in this regard.{' '}
              </Paragraph>
            </Col>
            <Col col={12}>
              <Button
                as="a"
                href="http://cmdphilippines.com/"
                target="_blank"
                rel="noopener noreferrer"
                variant="white"
              >
                Learn More
              </Button>
            </Col>
          </Row>
        </Container>
      </ScaledElement>
    </Section>
  )
}

export default Cmd
